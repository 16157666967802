<template>
  <div class="content">
    <!--[main]-->
    <MenuForm ref="form" :pageName="pageName"></MenuForm>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid justify-content-end">
          <li class="listGrid-item">
            <router-link class="btn btn-white" :to="{ name: 'ShopMenu' }">キャンセル</router-link>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-main" :handle-submit="register" button-text="登録する" />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import MenuForm from './Form.vue';

export default {
  name: 'ShopGalleryPhotoRegister',
  data: function() {
    return {
      pageName: 'メニュー',
    };
  },
  components: {
    MenuForm,
  },
  methods: {
    register: function() {
      this.$refs.form.handleSubmit();
    },
  },
};
</script>
